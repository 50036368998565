import React from "react"
import {GatsbyImage, getSrc} from "gatsby-plugin-image";
import { motion } from 'framer-motion'
import { fadeBumpDelayLong, fadeBumpDelay, fadeBump, fade } from "../helpers/transitionHelper"
import { injectIntl } from "gatsby-plugin-intl-v3";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { graphql } from "gatsby";
import SEO from "../components/seo"

const AboutPage = ({ intl, data  }) => {
  const { title, details, images } = data.contentfulAbout;

  return <>
    <SEO
      title={intl.formatMessage({ id: "about" })}
      image={getSrc(images[0])}
    />
    <motion.section
      initial="initial"
      animate="enter"
      exit="exit"
      data-scroll-section
    >
      <div className="pt-40 md:pt-15vw pb-24 md:pb-40 overflow-hidden relative">

        <motion.div variants={fadeBumpDelay} className="hidden md:block absolute top-0 left-0 z-0 w-2/12 xl:pr-8 mt-55vh -ml-8" data-scroll data-scroll-speed="3">
          <GatsbyImage
            image={data.contentfulMainPage.horizontal[1].gatsbyImageData}
            className="w-full opacity-0 md:opacity-50"
            objectPosition="right"
            alt={data.contentfulMainPage.horizontal[1].title} />
        </motion.div>

        <motion.div variants={fadeBumpDelayLong} className="hidden md:block absolute bottom-0 right-0 z-0 w-2/12 xl:pl-6 mb-25vh -mr-8" data-scroll data-scroll-speed="3">
          <GatsbyImage
            image={data.contentfulMainPage.horizontal[0].gatsbyImageData}
            className="w-full opacity-0 md:opacity-50"
            objectPosition="right"
            alt={data.contentfulMainPage.horizontal[0].title} />
        </motion.div>

        <div className="w-full md:w-1/2 md:pl-16 xl:w-1/2 3xl:w-5/12 mx-auto relative mb-12 md:mb-20">
          <div className="relative index-image pt-7vw">
            <motion.span variants={fadeBumpDelayLong} className="text-xl md:text-2xl uppercase font-sans tracking-widest md:upright absolute bottom-0 right-0 md:right-auto z-10 md:left-0 -mb-4 pb-px mr-8 md:-ml-5 md:mb-24 xl:mb-32 md:pb-0" data-scroll data-scroll-speed="0.65">
              {intl.formatMessage({ id: "bio" })}</motion.span>

            <motion.div variants={fade} className="relative overflow-hidden w-full h-full -mt-2vw" data-scroll data-scroll-speed="1.25">
              <div className="opacity-75">
                <GatsbyImage
                  image={images[0].gatsbyImageData}
                  alt={images[0].title}
                  className="w-full image-scale-in" />
              </div>
            </motion.div>
          </div>

          <motion.div
            variants={fadeBump}
            className="absolute top-0 right-0 z-10 px-4 md:px-0 -mt-20vw md:-mt-5vw"
            data-scroll
            data-scroll-speed="0.5"
          >
            <h1 className="text-10xlvw md:text-6xlvw xl:text-5xlvw 3xl:text-4xlvw w-full pr-10vw md:pr-0 md:w-full md:ml-auto md:-mr-15vw xl:w-11/12 xl:-mr-15vw leading-minimal uppercase">
              {title}
            </h1>
          </motion.div>
        </div>

        <motion.div variants={fade} data-scroll data-scroll-speed="1.25">
          <div className="w-full md:w-1/2 md:pl-16 xl:w-1/2 mx-auto relative max-w-2xl">
            <div className="px-4 md:px-0 w-11/12 md:w-full">
              <div className="mb-8 md:mb-12">
                <span className="content">
                  {renderRichText(details)}

                </span>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.section>

  </>;
}

export default injectIntl(AboutPage)


export const query = graphql`
    query ($language: String) {
        contentfulAbout(
            contentful_id: { eq: "1qLEX3IkAzwVBKKXmEC9HN" }
            node_locale: { eq: $language }
        ) {
            title
            details {
                raw
            }
            images {
                title
                description
                gatsbyImageData(layout: CONSTRAINED, width: 1200, height: 1400)
            }
        }
        contentfulMainPage(
            contentful_id: { eq: "3IemKmfKhhIl4iKQaLPfme" }
            node_locale: { eq: $language }
        ) {
            horizontal {
                title
                gatsbyImageData(layout: CONSTRAINED, width: 600, height: 1000)
            }
        }
    }
`;
